import { AttributeValue } from '@msdyn365-commerce/retail-proxy';

export const getAttributeByName = (attributes: AttributeValue[] | undefined, keyName: string | undefined): AttributeValue | undefined => {
    return attributes?.find((attr: AttributeValue) => attr.Name?.toLowerCase() === keyName?.toLowerCase());
};
export const getAttributeByKeyName = (
    attributes: AttributeValue[] | undefined,
    keyName: string | undefined
): AttributeValue | undefined => {
    return attributes?.find((attr: AttributeValue) => attr.KeyName?.toLowerCase() === keyName?.toLowerCase());
};
